import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";

import Layout from "src/components/layout";
import SEO from "src/components/seo";

export default function Template({ data }: any) {
  const { mdx } = data;
  const { frontmatter, body, excerpt } = mdx;
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        image={frontmatter.thumbnail.publicURL}
        path={frontmatter.slug}
        description={excerpt}
        author="Silvercast"
      />
      <h1 className="text-ui-1 text-5xl font-extrabold">{frontmatter.title}</h1>
      <div className="flex justify-center place-items-center my-8">
        <div className="mr-5 text-right flex-1">
          <div className="text-ui-5 text-sm">Last Updated</div>
          <div className="text-ui-1 font-medium">{frontmatter.date}</div>
        </div>
        <StaticImage width={81} height={81} src="../content/icon.jpg" alt="Silvercast" className="rounded-full" />
        <div className="ml-5 text-left flex-1">
          <div className="text-ui-5 text-sm">Author</div>
          <div className="text-ui-1 font-medium">Silvercast</div>
        </div>
      </div>
      <GatsbyImage
        image={frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        objectFit="contain"
        alt={`Silvercast | ${frontmatter.title}`}
      />
      <MDXRenderer>{body}</MDXRenderer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        thumbnail {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
